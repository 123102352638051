import React, { Component } from 'react';
import { connect } from 'react-redux';
//import Popover from '../../components/uielements/popover';
import { Popover, Avatar, Icon } from 'antd';
import * as authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';


const iconStyle = {
  marginRight: '10px'
};


class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }


  render() {
    const content = (
      <TopbarDropdownWrapper onClick={this.props.clicked} className="isoUserDropdown">
        <a className="isoDropdownLink" onClick={() => this.props.actions.logout()}>
            <Icon type={"logout"} style={iconStyle} /> Abmelden
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
            <span className={"username"}>{this.props.name}</span>
            <Avatar size="large"  icon="user" ></Avatar>
        </div>
      </Popover>
    );
  }
}

 function mapStateToProps(state) {
     const { Auth } = state;
     const { name } = Auth;

     return {
         name
     }
 }

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authAction, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TopbarUser);
