import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout, Icon } from 'antd';
import Scrollbars from '../../components/utility/customScrollBar.js';
import { Menu } from 'antd';
import SidebarWrapper from './sidebar.style';
import * as appActions from '../../redux/app/actions';
import Logo from '../../components/utility/logo';
import { getCurrentTheme } from '../ThemeSwitcher/config';
import { themeConfig } from '../../config';
import {bindActionCreators} from "redux";


/*const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
*/
const { Sider } = Layout;


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }




  handleClick(e) {
    this.props.appActions.changeCurrent([e.key]);
    if (this.props.view === 'MobileView') {
      setTimeout(() => {
        this.props.appActions.toggleCollapsed();
        this.props.appActions.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(this.props.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = this.props.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    this.props.appActions.changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  render() {
    const { url } = this.props;
    const customizedTheme = getCurrentTheme('sidebarTheme', themeConfig.theme);
    const collapsed = clone(this.props.collapsed) && !clone(this.props.openDrawer);
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = event => {
      if (this.props.openDrawer === false) {
        this.props.appActions.toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (this.props.openDrawer === true) {
          this.props.appActions.toggleOpenDrawer();
      }
      return;
    };
    const scrollheight = this.props.height;

    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    /*const submenuStyle = {
          backgroundColor: 'rgba(0,0,0,0.3)',
          color: customizedTheme.textColor,
    };*/
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width="240px"
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: scrollheight - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              mode={mode}
              openKeys={collapsed ? [] : this.props.openKeys}
              selectedKeys={this.props.current}
              onOpenChange={this.onOpenChange}
              className="isoDashboardMenu"
            >

                <Menu.Item>
                    <Link to={`${url}/Start`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="home" />
                            <span className="nav-text">
                               Start
                            </span>
                          </span>
                    </Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to={`${url}/Help`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="question-circle" />
                            <span className="nav-text">
                               Hilfe
                            </span>
                          </span>
                    </Link>
                </Menu.Item>

                {this.props.isAdmin &&
                <Menu.Item>
                    <Link to={`${url}/Import`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="cloud-upload"/>
                            <span className="nav-text">
                               Import
                            </span>
                          </span>
                    </Link>
                </Menu.Item>
                }

                {this.props.isAdmin &&
                <Menu.Item>
                    <Link to={`${url}/Users`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <Icon type="user"/>
                            <span className="nav-text">
                               Benutzerverwaltung
                            </span>
                          </span>
                    </Link>
                </Menu.Item>
                }



            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

function mapStateToProps(state) {
    const { Auth, App } = state;
    const {collapsed, view, height, openDrawer, openKeys, current} = App;
    const { idToken, isAdmin } = Auth;

    return {
        openKeys,
        collapsed,
        view,
        height,
        openDrawer,
        current,
        idToken,
        isAdmin
    };
}

function mapDispatchToProps(dispatch) {
    return{
        appActions: bindActionCreators(appActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);