import StartActions from "./actions";

const initState = {
    kommunen: [],
    ortsteile: [],
    strassen: [],
    projekteintern: [],
    actKommune1: "",
    actKommune2: "",
    actOrtsteil: "",
    actStrasse: "",
    actProjektnummer: "",
    topFilter: {},
    tableLoading: false,
    tableData: [],
    infosTableGruda: {page: 1, results: 25, sortField: "GrudaID", sortOrder: "descend"},
    paginationGruda: {current: 0, pageSize: 25},
};

export default function StartReducer(state = initState, action) {
  switch (action.type) {

      case StartActions.RESET_START:
          return Object.assign({}, state, {
              actKommune1: "",
              actKommune2: "",
              actOrtsteil: "",
              actStrasse: "",
              actProjektnummer: "",
              tableData: []
          });
      case StartActions.LOAD_KOMMUNEN_SUCCESS:
          return Object.assign({}, state, {
              kommunen : action.response.data.kommunen
          });
      case StartActions.LOAD_ORTSTEILE_SUCCESS:
          return Object.assign({}, state, {
              ortsteile : action.response.data.ortsteile
          });
      case StartActions.LOAD_STRASSEN_SUCCESS:
          return Object.assign({}, state, {
              strassen : action.response.data.strassen
          });
      case StartActions.LOAD_PROJEKTNUMMERN_SUCCESS:
          return Object.assign({}, state, {
              projekteintern : action.response.data.projektnummern
          });

      case StartActions.SET_KOMMUNE1:
          return Object.assign({}, state, {
              actKommune1 : action.kommune,
              actKommune2 : "",
              actOrtsteil: "",
              actStrasse: "",
              actProjektnummer: "",
              strassen: [],
              topFilter: {OrtObjekt: action.kommune}
          });
      case StartActions.SET_KOMMUNE2:
          return Object.assign({}, state, {
              actKommune1 : "",
              actKommune2: action.kommune,
              actOrtsteil: "",
              actStrasse: "",
              actProjektnummer: "",
              topFilter: {OrtObjekt: action.kommune}
          });
      case StartActions.SET_ORTSTEIL:
          return Object.assign({}, state, {
              actOrtsteil : action.ort,
              actStrasse: "",
              topFilter: {OrtObjekt: state.actKommune1, OrtsteilObjekt: action.ort}
          });
      case StartActions.SET_STRASSE:
          return Object.assign({}, state, {
              actStrasse : action.str,
              topFilter: {OrtObjekt: state.actKommune1, OrtsteilObjekt: state.actOrtsteil, StrasseObjekt: action.str}
          });
      case StartActions.SET_PROJEKTNUMMER:
          return Object.assign({}, state, {
              actProjektnummer : action.prj,
              topFilter: {OrtObjekt: state.actKommune1, ProjektnummerIntern: action.prj}
          });

      case StartActions.LOAD_TABLE_REQUEST:
          return Object.assign({}, state, {
              tableLoading : true,
          });
      case StartActions.LOAD_TABLE_SUCCESS:
          const pagination = { ...state.paginationGruda };
          pagination.total = action.response.data.Count;
          return Object.assign({}, state, {
              tableLoading : false,
              tableData : action.response.data.Gruda,
              paginationGruda: pagination
          });
      case StartActions.LOAD_TABLE_ERROR:
          return Object.assign({}, state, {
              tableLoading : false,
          });
      case StartActions.SET_GRUDA_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableGruda: action.infos
          });
      case StartActions.SET_GRUDA_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationGruda: action.pager
          });

    default:
      return state;
  }
}

