import {CALL_API} from "../../helpers/middleware";


const UsersActions = {
    LOAD_USERS_REQUEST : 'LOAD_USERS_REQUEST',
    LOAD_USERS_SUCCESS : 'LOAD_USERS_SUCCESS',
    LOAD_USERS_ERROR : 'LOAD_USERS_ERROR',
    LOAD_USERS_GROUPS_REQUEST : 'LOAD_USERS_GROUPS_REQUEST',
    LOAD_USERS_GROUPS_SUCCESS : 'LOAD_USERS_GROUPS_SUCCESS',
    LOAD_USERS_GROUPS_ERROR : 'LOAD_USERS_GROUPS_ERROR',
    SET_USERS_PAGE : 'SET_USERS_PAGE',
    SET_USERS_TABLE_INFO: 'SET_USERS_TABLE_INFO',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_USERS_DETAILS_VISIBLE: 'SET_USERS_DETAILS_VISIBLE',
    SAVE_USERS_REQUEST : 'SAVE_USERS_REQUEST',
    SAVE_USERS_SUCCESS : 'SAVE_USERS_SUCCESS',
    SAVE_USERS_ERROR : 'SAVE_USERS_ERROR',
    LOAD_USERS_DETAILS_REQUEST : 'LOAD_USERS_DETAILS_REQUEST',
    LOAD_USERS_DETAILS_SUCCESS : 'LOAD_USERS_DETAILS_SUCCESS',
    LOAD_USERS_DETAILS_ERROR : 'LOAD_USERS_DETAILS_ERROR',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
    ADD_PROJECT: 'ADD_PROJECT',
    LOAD_USERS_PROJECTS_REQUEST: 'LOAD_USERS_PROJECTS_REQUEST',
    LOAD_USERS_PROJECTS_SUCCESS: 'LOAD_USERS_PROJECTS_SUCCESS',
    LOAD_USERS_PROJECTS_ERROR: 'LOAD_USERS_PROJECTS_ERROR',
    RESET_USER_DETAIL: 'RESET_USER_DETAIL',
    DELETE_USERS_REQUEST : 'DELETE_USERS_REQUEST',
    DELETE_USERS_SUCCESS : 'DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR : 'DELETE_USERS_ERROR',
};

export default UsersActions;


export function LoadUsers(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'users/list',
            postData: {
                results: 25,
                ...params,
            },
            types: [UsersActions.LOAD_USERS_REQUEST, UsersActions.LOAD_USERS_SUCCESS, UsersActions.LOAD_USERS_ERROR]
        }
    };
}


export function LoadUsersGroups(visible, UserID = false) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users/groups',
            types: [UsersActions.LOAD_USERS_GROUPS_REQUEST, UsersActions.LOAD_USERS_GROUPS_SUCCESS, UsersActions.LOAD_USERS_GROUPS_ERROR]
        }
    }
}

export function LoadUsersDetails(UserID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users/'+UserID,
            types: [UsersActions.LOAD_USERS_DETAILS_REQUEST, UsersActions.LOAD_USERS_DETAILS_SUCCESS, UsersActions.LOAD_USERS_DETAILS_ERROR]
        }
    }
}

export function loadProjects() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users/projects',
            types: [UsersActions.LOAD_USERS_PROJECTS_REQUEST, UsersActions.LOAD_USERS_PROJECTS_SUCCESS, UsersActions.LOAD_USERS_PROJECTS_ERROR]
        }
    }
}


export function saveUserDetail(values, UserID) {

    if(UserID) {
        return {
            [CALL_API]: {
                reqType: 'PUT',
                endpoint: 'users/'+UserID,
                postData: values,
                types: [UsersActions.SAVE_USERS_REQUEST, UsersActions.SAVE_USERS_SUCCESS, UsersActions.SAVE_USERS_ERROR]
            }
        }
    } else {
        return {
            [CALL_API]: {
                reqType: 'POST',
                endpoint: 'users',
                postData: values,
                types: [UsersActions.SAVE_USERS_REQUEST, UsersActions.SAVE_USERS_SUCCESS, UsersActions.SAVE_USERS_ERROR]
            }
        }
    }

}



export function deleteUser(UserID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'users/'+UserID,
            types: [UsersActions.DELETE_USERS_REQUEST, UsersActions.DELETE_USERS_SUCCESS, UsersActions.DELETE_USERS_ERROR]
        }
    }
}


export function setUsersPage(pager) {
    return {
        type: UsersActions.SET_USERS_PAGE,
        pager
    };
}

export function setUsersTableInfo(infos) {
    return {
        type: UsersActions.SET_USERS_TABLE_INFO,
        infos
    };
}

export function setSearchText(search) {
    return {
        type: UsersActions.SET_SEARCH_TEXT,
        search
    };
}

export function setUsersTab(tab) {
    return {
        type: UsersActions.SET_USERS_TAB,
        tab
    };
}

export function showUsersDetail(visible, UserID = false) {
    return {
        type: UsersActions.SET_USERS_DETAILS_VISIBLE,
        visible,
        UserID
    }
}


export function resetDetail() {
    return {
        type: UsersActions.RESET_USER_DETAIL
    };
}

export function addProject() {
    return {
        type: UsersActions.ADD_PROJECT
    };
}

export function removeProject(key) {
    return {
        type: UsersActions.REMOVE_PROJECT,
        key
    };
}




