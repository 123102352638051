import StartDetailActions from "./detailActions";


const initState = {
    Gruda: [],
    loading: true,
    loadingGruda: true,
    isSaving: false,
    errorSaving: false,
    edit: true,
    GrudaRev: [],
    loadingGrudaRev: false
};

export default function StartDetailReducer(state = initState, action) {
  switch (action.type) {
      case StartDetailActions.LOAD_GRUDA_DETAIL_REQUEST:
        return Object.assign({}, state, {
            loadingGruda: true
        });
      case StartDetailActions.LOAD_GRUDA_DETAIL_SUCCESS:
        return Object.assign({}, state, {
            Gruda: action.response.data.Gruda,
            loadingGruda: false,
        });
      case StartDetailActions.LOAD_GRUDA_DETAIL_ERROR:
        return Object.assign({}, state, {
            loadingGruda: false
        });
      case StartDetailActions.STOP_LOADING:
          return Object.assign({}, state, {
              loadingGruda: false
          });

      case StartDetailActions.RESET_GRUDA_DETAIL:
          return Object.assign({}, state, initState);
      case StartDetailActions.SAVE_GRUDA_DETAIL_REQUEST:
          return Object.assign({}, state, {
              isSaving: true,
              errorSaving: false
          });
      case StartDetailActions.SAVE_GRUDA_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              Gruda: action.response.data ? action.response.data.Gruda : {},
              isSaving: false,
              errorSaving: false,
          });
      case StartDetailActions.SAVE_GRUDA_DETAIL_ERROR:
          return Object.assign({}, state, {
              isSaving: false,
              errorSaving: true
          });
      case StartDetailActions.TOGGLE_EDIT:
          return Object.assign({}, state, {
              edit: !state.edit
          });
      case StartDetailActions.LOAD_GRUDA_REV_REQUEST:
          return Object.assign({}, state, {
              loadingGrudaRev: true,
              GrudaRev: []
          });
      case StartDetailActions.LOAD_GRUDA_REV_SUCCESS:
          return Object.assign({}, state, {
              GrudaRev: action.response.data.GrudaRev,
              loadingGrudaRev: false,
          });
      case StartDetailActions.LOAD_GRUDA_REV_ERROR:
          return Object.assign({}, state, {
              loadingGrudaRev: false
          });

    default:
      return state;
  }
}


/*

case StartDetailActions.LOAD_ANTRAG_REV_REQUEST:
          return Object.assign({}, state, {
              loadingGrudaRev: true
          });
      case StartDetailActions.LOAD_ANTRAG_REV_SUCCESS:
          return Object.assign({}, state, {
              GrudaRev: action.response.data.GrudaRev,
              loadingGrudaRev: false,
          });
      case StartDetailActions.LOAD_ANTRAG_REV_ERROR:
          return Object.assign({}, state, {
              loadingGrudaRev: false
          });


 */

