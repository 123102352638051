import notification from '../components/notification';
import { history } from '../redux/store';
import loginActions from '../redux/auth/actions';

//let BASE_URL = 'http://localhost:9000/';
//let BASE_URL = 'https://api.brinx.me/';
let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;
let refreshing = false;
let refreshingQueue = [];

export function callApi(endpoint, type, data = {}, raw = false) {

    let token = localStorage.getItem('id_token') || null;
    let config = {};

    let url = BASE_URL;
    url +='api/' + API_VERSION + "/" ;


    if(type === 'GET' || type === 'DELETE') {
        config = {
            method: type,
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 },
            cache: "no-cache"
        };
    } else if(type === 'POST' || type === 'PUT') {
        config = {
            method: type,
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 },
            cache: "no-cache",
            body: JSON.stringify(data)
        };
    }

    if(raw) {
        return fetch(url + endpoint, config)
            .then(function(response) {
                if (!response.ok) {
                    return Promise.reject(response);
                }
return response;
            }).catch(err => {
                return Promise.reject(err);
            });
    }

    return fetch(url + endpoint, config)
        .then(function(response) {
            if (!response.ok) {
                return Promise.reject(response);
            } else {
                return response.json();
            }

        }).then(function(response) {
            if(response.alert && response.alert.message) {
                notification(response.alert.type, response.alert.message);
            }
            if(typeof(response.redirect) !== 'undefined' && response.redirect) {
                history.push(response.redirect);
            }
            return response;
        }).catch(err => {
            return Promise.reject(err);
        });
}


export const CALL_API = Symbol('Call API');

export default store => next => action => {

    const callAPI = action[CALL_API];




    // So the middleware doesn't get applied to every single action
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { endpoint, types, reqType, postData, raw } = callAPI;

    const [ requestType, successType, errorType ] = types;


    next({
        type: requestType
    });

    return callApi(endpoint ,reqType, postData, raw).then(

        response =>
            next({
                response,
                type: successType
            }),
        function(error) {
            errorHandling(error, endpoint ,reqType, postData, raw, successType, errorType, next);
        }
    );
};



function errorHandling(error, endpoint ,reqType, postData, raw, successType, errorType, next) {
    if (error.status === 500 || error.status === 400 || error.status === 401) {

        if(error.status === 401) {

            if(endpoint !== 'auth/login') {
                if(refreshing) {
                    refreshingQueue.push({error, endpoint ,reqType, postData, raw, successType, errorType, next});
                }
                else {
                    refreshing = true;
                    callApi('auth/token', 'POST', {refresh_token: localStorage.getItem('refresh_token')}, true).then(response =>
                        {
                            refreshing = false;
                            next({
                                response,
                                type: loginActions.LOGIN_SUCCESS
                            })
                        },
                        function (error) {
                            refreshing = false;
                            localStorage.removeItem('id_token');
                            localStorage.removeItem('refresh_token');
                            window.location.href = "/signin";
                        }
                    ).then(() => {
                        refreshingQueue.push({error, endpoint ,reqType, postData, raw, successType, errorType, next});

                        refreshingQueue.map(refresh => {

                            callApi(endpoint ,reqType, postData, raw).then(
                                response =>
                                    next({
                                        response,
                                        type: successType
                                    }),
                                function(error) {

                                    errorHandling(error, endpoint ,reqType, postData, raw, successType, errorType, next);
                                }
                            );
                        });
                        refreshingQueue = [];
                    });
                }

            }

        }

        error.json().then(result => {
            if (result.alert && result.alert.message) {
                notification(result.alert.type, result.alert.message);
            }
            next({
                type: errorType,
                message: result.alert.message
            });
        });


    } else {
        notification('error', "Es ist ein unbekannter Fehler aufgetreten.");
        next({
            type: errorType
        });
    }
}