import {CALL_API} from "../../helpers/middleware";


const HelpActions = {
    SEND_HELP_REQUEST : 'SEND_HELP_REQUEST',
    SEND_HELP_SUCCESS : 'SEND_HELP_SUCCESS',
    SEND_HELP_ERROR : 'SEND_HELP_ERROR',

    LOAD_USERHELPINFO_REQUEST : 'LOAD_USERHELPINFO_REQUEST',
    LOAD_USERHELPINFO_SUCCESS : 'LOAD_USERHELPINFO_SUCCESS',
    LOAD_USERHELPINFO_ERROR : 'LOAD_USERHELPINFO_ERROR',
};

export default HelpActions;


export function loadUserInfo() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Help/Userinfo',
            types: [HelpActions.LOAD_USERHELPINFO_REQUEST, HelpActions.LOAD_USERHELPINFO_SUCCESS, HelpActions.LOAD_USERHELPINFO_ERROR]
        }
    };
}


export function sendHelp(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'Help/Send',
            postData: values,
            types: [HelpActions.SEND_HELP_REQUEST, HelpActions.SEND_HELP_SUCCESS, HelpActions.SEND_HELP_ERROR]
        }
    };
}






