
import actions, { getView } from './actions';



const initState = {
    collapsed: window.innerWidth > 1220 ? false : true,
    view: getView(window.innerWidth),
    height: window.innerHeight,
    openDrawer: false,
    openKeys: [],
    current: []
};


export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.COLLPSE_CHANGE:
            return Object.assign({}, state, {
                collapsed: !state.collapsed
            });
        case actions.COLLPSE_OPEN_DRAWER:
            return Object.assign({}, state, {
                openDrawer: !state.openDrawer
            });
        case actions.TOGGLE_ALL:
            if (state.view !== action.view || action.height !== state.height) {
                const height = action.height ? action.height : state.height;

                return Object.assign({}, state, {
                    collapsed: action.collapsed,
                    view: action.view,
                    height: height,
                });
            }
            break;
        case actions.CHANGE_OPEN_KEYS:
            return Object.assign({}, state, {
                openKeys: action.openKeys
            });
        case actions.CHANGE_CURRENT:
            return Object.assign({}, state, {
                current: action.current
            });
        default:
            return state;
    }
    return state;
}