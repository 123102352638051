import ImportActions from "./actions";



const initState = {
    importDataTable: [],
    importing: false,
};

export default function HelpReducer(state = initState, action) {
    switch (action.type) {

        case ImportActions.SET_TABLE_DATA:
            return Object.assign({}, state, {
                importDataTable : action.data
            });

        case ImportActions.CLEAR_TABLE_DATA:
            return Object.assign({}, state, {
                importDataTable : []
            });

        case ImportActions.IMPORT_DATA_REQUEST:
            return Object.assign({}, state, {
                importing : true
            });
        case ImportActions.IMPORT_DATA_SUCCESS:
            return Object.assign({}, state, {
                importing : false,
                importDataTable : []
            });
        case ImportActions.IMPORT_DATA_ERROR:
            return Object.assign({}, state, {
                importing : false
            });


        default:
            return state;
    }
}


/*
const initState = {
    EMail: "",
    Name: "",
    Telefon: "",
    reset: false,
    sending: false
};

export default function HelpReducer(state = initState, action) {
  switch (action.type) {

      case HelpActions.LOAD_USERHELPINFO_SUCCESS:
          return Object.assign({}, state, {
              EMail : action.response.data.EMail,
              Name : action.response.data.Name,
              Telefon : action.response.data.Telefon,
          });

      case HelpActions.SEND_HELP_REQUEST:
          return Object.assign({}, state, {
              sending : true,
              reset : false
          });
      case HelpActions.SEND_HELP_SUCCESS:
          return Object.assign({}, state, {
              sending : false,
              reset : true,
          });
      case HelpActions.SEND_HELP_ERROR:
          return Object.assign({}, state, {
              sending : false,
              reset : false
          });

    default:
      return state;
  }
}
*/
