import UsersActions from "./actions";


const initState = {
    loadingUsers: true,
    paginationUsers: {current: 0, pageSize: 25},
    Users: [],
    infosTableUsers: {page: 1, results: 25, sortField: "UserID", sortOrder: "descend"},
    search: "",
    UsersDetailsVisible: false,
    UsersDetailsUserID: false,
    User: {},
    UsersGroups: [],
    savingUsers: false,
    keysProject: [],
    allProjects: [],
    maxKeyProjects: 0,
    deletingUser: false
};

export default function UsersReducer(state = initState, action) {
  switch (action.type) {
    case UsersActions.LOAD_USERS_REQUEST:
        return Object.assign({}, state, {
            loadingUsers: true
        });
    case UsersActions.LOAD_USERS_SUCCESS:
        const pagination = { ...state.paginationUsers };
        pagination.total = action.response.data.Count;
        return Object.assign({}, state, {
            Users: action.response.data.Users,
            loadingUsers: false,
            paginationUsers: pagination
        });
      case UsersActions.LOAD_USERS_ERROR:
          return Object.assign({}, state, {
              loadingUsers: false
          });
      case UsersActions.SET_USERS_PAGE:
          return Object.assign({}, state, {
              paginationUsers: action.pager
          });
      case UsersActions.SET_USERS_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableUsers: action.infos
          });
      case UsersActions.SET_SEARCH_TEXT:
          return Object.assign({}, state, {
              search: action.search,
              paginationUsers: {current: 1, pageSize: 25},
              infosTableUsers: {page: 1, results: 25, sortField: "UserID", sortOrder: "descend"}
          });
      case UsersActions.SET_USERS_DETAILS_VISIBLE:
          return Object.assign({}, state, {
              UsersDetailsVisible: action.visible,
              UsersDetailsUserID: action.UserID,
              User: {}
          });
      case UsersActions.LOAD_USERS_GROUPS_SUCCESS:
          return Object.assign({}, state, {
              UsersGroups: action.response.data.UsersGroups
          });
      case UsersActions.SAVE_USERS_REQUEST:
          return Object.assign({}, state, {
              savingUsers: true
          });
      case UsersActions.SAVE_USERS_SUCCESS:
          return Object.assign({}, state, {
              savingUsers: false,
              UsersDetailsVisible: false
          });
      case UsersActions.SAVE_USERS_ERROR:
          return Object.assign({}, state, {
              savingUsers: false
          });
      case UsersActions.LOAD_USERS_DETAILS_REQUEST:
          return Object.assign({}, state, {
              User: {}
          });
      case UsersActions.LOAD_USERS_DETAILS_SUCCESS:
          return Object.assign({}, state, {
              User: action.response.data.User,
              maxKeyProjects: action.response.data.User.Projects.length,
              keysProject: action.response.data.User.Projects.map((prj, i) => i),
          });
      case UsersActions.LOAD_USERS_DETAILS_ERROR:
          return Object.assign({}, state, {
              UsersDetailsVisible: false
          });
      case UsersActions.ADD_PROJECT:
          //let newID = Math.max(state.keysProject)+1 || 1;
          let newID = state.maxKeyProjects+1;
          return Object.assign({}, state, {
              keysProject: [...state.keysProject, newID],
              maxKeyProjects: newID
          });
      case UsersActions.REMOVE_PROJECT:
          return Object.assign({}, state, {
              keysProject: state.keysProject.filter(k => k !== action.key)
          });
      case UsersActions.LOAD_USERS_PROJECTS_REQUEST:
          return Object.assign({}, state, {
              allProjects: []
          });
      case UsersActions.LOAD_USERS_PROJECTS_SUCCESS:
          return Object.assign({}, state, {
              allProjects: action.response.data.projects
          });
      case UsersActions.LOAD_USERS_PROJECTS_ERROR:
          return Object.assign({}, state, {
              allProjects: []
          });
      case UsersActions.RESET_USER_DETAIL:
          return Object.assign({}, state, {
              keysProject: [],
              maxKeyProjects: 0,
          });
      case UsersActions.DELETE_USERS_REQUEST:
          return Object.assign({}, state, {
              deletingUser: true
          });
      case UsersActions.DELETE_USERS_SUCCESS:
          return Object.assign({}, state, {
              deletingUser: false,
              UsersDetailsVisible: false
          });
      case UsersActions.DELETE_USERS_ERROR:
          return Object.assign({}, state, {
              deletingUser: false
          });
    default:
      return state;
  }
}

