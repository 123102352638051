const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const loginMethod = process.env.REACT_APP_LOGIN;

export {
  themeConfig,
    loginMethod
};
