// The middleware to call the API for quotes
import { CALL_API } from '../../helpers/middleware';
import { history } from '../store';

const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR'
};

export default actions;

export function login(payload) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            postData: payload,
            endpoint: 'auth/login',

            types: [actions.LOGIN_REQUEST, actions.LOGIN_SUCCESS, actions.LOGIN_ERROR]
        }
    };
}

/*
export function logout() {
    setLogout().then(() => {

    } );
}
*/




function setLogout() {
    return {
        type: actions.LOGOUT
    };
}

export function logout() {
    history.push("/signin");
    return dispatch => {
        dispatch(setLogout());
    }
}