import actions from './actions';
var jwtDecode = require('jwt-decode');

const initState =
    {
        idToken: localStorage.getItem('id_token') || false,
        isAdmin: (localStorage.getItem('id_token') && jwtDecode(localStorage.getItem('id_token')).admin),
        name: (localStorage.getItem('id_token') && jwtDecode(localStorage.getItem('id_token')).name),
        userGroup: (localStorage.getItem('id_token') && jwtDecode(localStorage.getItem('id_token')).group),
        writePermissions: true,
        isLoggingIn: false
    };

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_REQUEST:
            return Object.assign({}, state, {
                isLoggingIn: true
            });
        case actions.LOGIN_SUCCESS:
            localStorage.setItem('id_token', action.response.data.token);
            localStorage.setItem('refresh_token', action.response.data.refresh_token);
            //return state.set('idToken', action.response.token);
            return Object.assign({}, state, {
                idToken: action.response.data.token,
                isAdmin: jwtDecode(localStorage.getItem('id_token')).admin,
                name: jwtDecode(localStorage.getItem('id_token')).name,
                userGroup: jwtDecode(localStorage.getItem('id_token')).group,
                isLoggingIn: false
            });
        case actions.LOGIN_ERROR:
            return Object.assign({}, state, {
                isLoggingIn: false
            });
        case actions.LOGOUT:
            localStorage.removeItem('id_token');
            localStorage.removeItem('refresh_token');
            return initState;
        default:
            return state;
    }
}
