import Auth from './auth/reducer';
import App from './app/reducer';
import UsersReducer from './Users/reducer';
import HelpReducer from './Help/reducer';
import ImportReducer from './Import/reducer';
import StartReducer from './Start/reducer';
import StartDetailReducer from './Start/detailReducer';
export default {
  Auth,
  App,
  UsersReducer,
  StartReducer,
  HelpReducer,
  StartDetailReducer,
  ImportReducer,
};
