import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import { history } from '../../redux/store';



const AdminRoute = ({ component: Component, isAdmin, ...rest }) => (
    <Route
        {...rest}
        render={props => isAdmin
            ? <Component {...props} />
            : history.goBack()}
    />
);


class AppRouter extends React.Component {
  render() {
    const { url, isAdmin } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${url}/Start`}
          component={asyncComponent(() => import('../Start'))}
        />
        <Route
          exact
          path={`${url}/Start/:ID`}
          component={asyncComponent(() => import('../Start/detail'))}
        />
        <Route
          exact
          path={`${url}/Start/File/:FileID`}
          component={asyncComponent(() => import('../../components/FileDownload'))}
        />
        <Route
          exact
          path={`${url}/Start/:ID/History`}
          component={asyncComponent(() => import('../Start/RevChanges'))}
        />
        <AdminRoute
            exact
            path={`${url}/Users`}
            component={asyncComponent(() => import('../Users/users'))}
            isAdmin={isAdmin}
        />
        <Route
            exact
            path={`${url}/Help`}
            component={asyncComponent(() => import('../Help'))}
        />
          <AdminRoute
              exact
              path={`${url}/Import`}
              component={asyncComponent(() => import('../Import'))}
              isAdmin={isAdmin}
          />
        {/*
          <Route
              exact
              path={`${url}/Antrag/:ID`}
              component={asyncComponent(() => import('../Antraege/detail'))}
          />
          <Route
              exact
              path={`${url}/Antrag/:ID/History`}
              component={asyncComponent(() => import('../Antraege/RevChanges'))}
          />
          <Route
              exact
              path={`${url}/Antrag/:ID/:Duplicate`}
              component={asyncComponent(() => import('../Antraege/detail'))}
          />
          <Route
              exact
              path={`${url}/file/:AntragID/:FileName`}
              component={asyncComponent(() => import('../FileDisplay'))}
          />
          */}
      </Switch>
    );
  }
}


export default AppRouter;
