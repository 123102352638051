import {CALL_API} from "../../helpers/middleware";


const StartActions = {
    LOAD_KOMMUNEN_REQUEST : 'LOAD_KOMMUNEN_REQUEST',
    LOAD_KOMMUNEN_SUCCESS : 'LOAD_KOMMUNEN_SUCCESS',
    LOAD_KOMMUNEN_ERROR : 'LOAD_KOMMUNEN_ERROR',

    LOAD_ORTSTEILE_REQUEST : 'LOAD_ORTSTEILE_REQUEST',
    LOAD_ORTSTEILE_SUCCESS : 'LOAD_ORTSTEILE_SUCCESS',
    LOAD_ORTSTEILE_ERROR : 'LOAD_ORTSTEILE_ERROR',

    LOAD_STRASSEN_REQUEST : 'LOAD_STRASSEN_REQUEST',
    LOAD_STRASSEN_SUCCESS : 'LOAD_STRASSEN_SUCCESS',
    LOAD_STRASSEN_ERROR : 'LOAD_STRASSEN_ERROR',

    LOAD_PROJEKTNUMMERN_REQUEST : 'LOAD_PROJEKTNUMMERN_REQUEST',
    LOAD_PROJEKTNUMMERN_SUCCESS : 'LOAD_PROJEKTNUMMERN_SUCCESS',
    LOAD_PROJEKTNUMMERN_ERROR : 'LOAD_PROJEKTNUMMERN_ERROR',

    SET_KOMMUNE1: 'SET_KOMMUNE1',
    SET_KOMMUNE2: 'SET_KOMMUNE2',
    SET_ORTSTEIL: 'SET_ORTSTEIL',
    SET_STRASSE: 'SET_STRASSE',
    SET_PROJEKTNUMMER: 'SET_PROJEKTNUMMER',

    LOAD_TABLE_REQUEST: 'LOAD_TABLE_REQUEST',
    LOAD_TABLE_SUCCESS: 'LOAD_TABLE_SUCCESS',
    LOAD_TABLE_ERROR: 'LOAD_TABLE_ERROR',

    SET_GRUDA_TABLE_INFO: 'SET_GRUDA_TABLE_INFO',
    SET_GRUDA_TABLE_PAGE: 'SET_GRUDA_TABLE_PAGE',

    RESET_START: 'RESET_START',
};

/*
    EXPORT_TABLE_REQUEST: 'EXPORT_TABLE_REQUEST',
    EXPORT_TABLE_SUCCESS: 'EXPORT_TABLE_SUCCESS',
    EXPORT_TABLE_ERROR: 'EXPORT_TABLE_ERROR'
 */

export default StartActions;





export function LoadTable(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'Start/Table',
            postData: {
                results: 25,
                ...params,
            },
            types: [StartActions.LOAD_TABLE_REQUEST, StartActions.LOAD_TABLE_SUCCESS, StartActions.LOAD_TABLE_ERROR]
        }
    };
}


export function LoadKommunen() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Start/Kommunen',
            types: [StartActions.LOAD_KOMMUNEN_REQUEST, StartActions.LOAD_KOMMUNEN_SUCCESS, StartActions.LOAD_KOMMUNEN_ERROR]
        }
    };
}

export function LoadOrtsteile(kommune) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Start/Ortsteile/'+kommune,
            types: [StartActions.LOAD_ORTSTEILE_REQUEST, StartActions.LOAD_ORTSTEILE_SUCCESS, StartActions.LOAD_ORTSTEILE_ERROR]
        }
    };
}

export function LoadStrassen(kommune,ort) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Start/Strassen/'+kommune+"/"+ort,
            types: [StartActions.LOAD_STRASSEN_REQUEST, StartActions.LOAD_STRASSEN_SUCCESS, StartActions.LOAD_STRASSEN_ERROR]
        }
    };
}


export function LoadProjektnummern(kommune) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Start/Projektnummern/'+kommune,
            types: [StartActions.LOAD_PROJEKTNUMMERN_REQUEST, StartActions.LOAD_PROJEKTNUMMERN_SUCCESS, StartActions.LOAD_PROJEKTNUMMERN_ERROR]
        }
    };
}


/*export function ExportTable(params = {}) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'Start/Export',
            postData: params,
            types: [StartActions.EXPORT_TABLE_REQUEST, StartActions.EXPORT_TABLE_SUCCESS, StartActions.EXPORT_TABLE_ERROR]
        }
    };
}
*/


export function setGrudaTablePage(pager) {
    return {
        type: StartActions.SET_GRUDA_TABLE_PAGE,
        pager
    };
};

export function setGrudaTableInfo(infos) {
    return {
        type: StartActions.SET_GRUDA_TABLE_INFO,
        infos
    };
};



export function setKommune1(kommune) {
        return {
            type: StartActions.SET_KOMMUNE1,
            kommune
        };
}

export function setKommune2(kommune) {
    return {
        type: StartActions.SET_KOMMUNE2,
        kommune
    };
}

export function setOrtsteil(ort) {
    return {
        type: StartActions.SET_ORTSTEIL,
        ort
    };
}

export function setStrasse(str) {
    return {
        type: StartActions.SET_STRASSE,
        str
    };
}

export function setProjektnummer(prj) {
    return {
        type: StartActions.SET_PROJEKTNUMMER,
        prj
    };
}

export function resetStart() {
    return {
        type: StartActions.RESET_START
    };
}



