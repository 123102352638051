import React from 'react';
import { Link } from 'react-router-dom';
//import logo from '../../image/logo.png';
//import logo from '../../image/logo.svg';
import logo from '../../image/logo-bunt.svg';
import logoSmall from '../../image/logo-small.png';

export default ({ collapsed }) => {
    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to="/app/Start">
                            <img alt={"logo"} src={logoSmall} height={"30%"} width={"50%"} />
                        </Link>
                    </h3>
                </div>
            ) : (
                <h3>
                    <Link to="/app/Start"><img alt={"logo"} src={logo} height={"100%"} width={"90%"} /></Link>
                </h3>
            )}
        </div>
    );
};
