import {CALL_API} from "../../helpers/middleware";

const StartDetailActions = {

    LOAD_GRUDA_DETAIL_REQUEST : 'LOAD_GRUDA_DETAIL_REQUEST',
    LOAD_GRUDA_DETAIL_SUCCESS : 'LOAD_GRUDA_DETAIL_SUCCESS',
    LOAD_GRUDA_DETAIL_ERROR : 'LOAD_GRUDA_DETAIL_ERROR',



    SAVE_GRUDA_DETAIL_REQUEST : 'SAVE_GRUDA_DETAIL_REQUEST',
    SAVE_GRUDA_DETAIL_SUCCESS : 'SAVE_GRUDA_DETAIL_SUCCESS',
    SAVE_GRUDA_DETAIL_ERROR : 'SAVE_GRUDA_DETAIL_ERROR',


    RESET_GRUDA_DETAIL: 'RESET_GRUDA_DETAIL',

    LOAD_GRUDA_REV_REQUEST: 'LOAD_GRUDA_REV_REQUEST',
    LOAD_GRUDA_REV_SUCCESS: 'LOAD_GRUDA_REV_SUCCESS',
    LOAD_GRUDA_REV_ERROR: 'LOAD_GRUDA_REV_ERROR',
    STOP_LOADING: 'STOP_LOADING'
};

export default StartDetailActions;


export function LoadGrudaDetail(GrudaID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Start/'+GrudaID,
            types: [StartDetailActions.LOAD_GRUDA_DETAIL_REQUEST, StartDetailActions.LOAD_GRUDA_DETAIL_SUCCESS, StartDetailActions.LOAD_GRUDA_DETAIL_ERROR]
        }
    };
}

export function LoadGrudaRev(GrudaID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'Start/'+GrudaID+'/REV',
            types: [StartDetailActions.LOAD_GRUDA_REV_REQUEST, StartDetailActions.LOAD_GRUDA_REV_SUCCESS, StartDetailActions.LOAD_GRUDA_REV_ERROR]
        }
    };
}




export function SaveGrudaDetail(GrudaID, values) {

    let type = 'PUT';

    if(GrudaID === "0") {
        type = 'POST';
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: 'Start/'+GrudaID,
            postData: values,
            types: [StartDetailActions.SAVE_GRUDA_DETAIL_REQUEST, StartDetailActions.SAVE_GRUDA_DETAIL_SUCCESS, StartDetailActions.SAVE_GRUDA_DETAIL_ERROR]
        }
    };
}





export function resetGruda() {
    return {
        type: StartDetailActions.RESET_GRUDA_DETAIL
    };
}


export function stopLoading() {
    return {
        type: StartDetailActions.STOP_LOADING
    };
}

